<template>
  <div class="main">
    <div>
      <div class="main__top">
        <img class="main__top-logo" src="/assets/logo1.png" />
        <div class="main__top-center">
          Расписка
          <img src="/assets/wave.svg" />
        </div>
        <p>АКВАПАРК HAWAII & MIAMI</p>
      </div>
      <div class="fields">
        <span v-if="isPhoneValue">Я,</span>
        <div class="inputs">
          <div v-if="isPhoneValue" class="inputs-item">
            <input
              v-model="surname"
              name="surname"
              type="text"
              :class="{ actborinp: v$.surname.$errors.length }"
              required
              placeholder="Введите фамилию"
            />
            <p v-if="v$.surname.$errors.length">* Это обязательное поле</p>
          </div>
          <div v-if="isPhoneValue" class="inputs-item">
            <input
              v-model="name"
              name="name"
              type="text"
              :class="{ actborinp: v$.name.$errors.length }"
              required
              placeholder="Введите имя"
            />
            <p v-if="v$.name.$errors.length">* Это обязательное поле</p>
          </div>
          <div v-if="isPhoneValue" class="inputs-item">
            <input
              v-model="birthday"
              name="birthday"
              v-mask="'##.##.####'"
              @input="validateDate"
              :class="{ actborinp: v$.birthday.$errors.length }"
              id="birthday"
              type="text"
              required
              placeholder="Введите дату рождения"
            />
            <p v-if="v$.birthday.$errors.length">* Это обязательное поле</p>
          </div>
          <div :class="{ nodatas: !isPhoneValue }" class="inputs-item">
            <input
              v-model="phone"
              name="phone"
              v-mask="'+7 (###) ### ##-##'"
              :class="{ actborinp: v$.phone.$errors.length, isExits: isExits }"
              id="phone"
              type="text"
              required
              placeholder="Введите номер телефона"
              @input="checkPhone"
            />
            <p v-if="v$.phone.$errors.length">* Это обязательное поле</p>
            <span v-if="isExits">Ваш номер подтвержден!</span>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="info">
          <p class="info-title">Подтверждаю, что:</p>
          <div class="row">
            <input
              type="checkbox"
              :class="{ actborcheck: v$.ch1.$errors.length }"
              v-model="ch1"
              required
              id="elem1"
            />
            <label for="elem1"
              >Ознакомлен (-а) с правилами посещения аквапарка «Hawaii, Miami
              Aquapark & SPA» и правилами поведения, размещенными на территории
              аквапарка «Hawaii, Miami Aquapark & SPA» рядом с
              аттракционами;</label
            >
          </div>
          <div class="row">
            <input
              type="checkbox"
              v-model="ch2"
              :class="{ actborcheck: v$.ch2.$errors.length }"
              required
              id="elem2"
            />
            <label for="elem2"
              >Беру полную ответственность за себя, и за жизнь и здоровье
              находящихся со мной детей на территории аквапарка «Hawaii, Miami
              Aquapark & SPA»;</label
            >
          </div>
          <div class="row">
            <input
              type="checkbox"
              v-model="ch3"
              :class="{ actborcheck: v$.ch3.$errors.length }"
              required
              id="elem3"
            />
            <label for="elem3"
              >Оплатить штраф за потерю браслета - 3600 тг.;</label
            >
          </div>
          <div class="row">
            <input
              type="checkbox"
              v-model="ch4"
              :class="{ actborcheck: v$.ch4.$errors.length }"
              required
              id="elem6"
            />
            <label for="elem6"
              >Оплатить штраф за потерю электронного браслета с ключом - 4200 тг.;</label
            >
          </div>
          <div class="row">
            <input
              type="checkbox"
              v-model="ch5"
              :class="{ actborcheck: v$.ch5.$errors.length }"
              required
              id="elem4"
            />
            <label for="elem4"
              >Оплатить за превышение времени 1 минута - 40 тг.;</label
            >
          </div>
          <div class="row">
            <input
              type="checkbox"
              v-model="ch6"
              :class="{ actborcheck: v$.ch6.$errors.length }"
              required
              id="elem5"
            />
            <label for="elem5">Имею при себе: купальник, плавки, сланцы.</label>
          </div>
          <article>Перед уходом оставляйте двери шкафчиков открытыми!</article>
          <button @click="sendData" id="buttonsend">Подтвердить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useVuelidate } from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
  duration: 2000,
});

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  directives: { mask },
  data: () => ({
    surname: "",
    name: "",
    birthday: "",
    phone: "",
    ch1: false,
    ch2: false,
    ch3: false,
    ch4: false,
    ch5: false,
    ch6: false,
    isPhoneValue: false,
    isExits: false,
  }),
  validations() {
    return {
      surname: { required },
      name: { required },
      birthday: { required },
      phone: { required },
      ch1: { sameAs: sameAs(true) },
      ch2: { sameAs: sameAs(true) },
      ch3: { sameAs: sameAs(true) },
      ch4: { sameAs: sameAs(true) },
      ch5: { sameAs: sameAs(true) },
      ch6: { sameAs: sameAs(true) },
    };
  },
  methods: {
    async checkPhone() {
      console.log(this.phone.length);
      if (this.phone.length === 18) {
        var originalPhone = this.phone;
        var cleanedPhone = originalPhone.replace(/\D/g, "");
        await axios
          .get(
            `https://admin.hawaii.kz/api/V1/check-lime-user?phone=${cleanedPhone}`
          )
          .then(async (res) => {
            res.data !== "exists"
              ? (this.isPhoneValue = true)
              : ((this.isPhoneValue = false), (this.isExits = true));
          });
      } else {
        this.isExits = false;
        this.isPhoneValue = false;
      }
    },
    validateDate() {
      if (this.birthday.length === 10) {
        let dateString = this.birthday;
        var dateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

        // Проверка соответствия формату
        if (!dateRegex.test(dateString)) {
          this.birthday = "01.01.2000"; // Возвращаем первое января 1000 года в случае неверного формата
        }

        // Извлечение компонентов даты из совпадений с регулярным выражением
        var matches = dateString.match(dateRegex);
        var day = parseInt(matches[1], 10);
        var month = parseInt(matches[2], 10);
        var year = parseInt(matches[3], 10);
        // Проверка валидности даты
        var isValidDate =
          year >= 1950 &&
          year <= 2024 &&
          month >= 1 &&
          month <= 12 &&
          day >= 1 &&
          day <= new Date(year, month, 0).getDate();

        if (!isValidDate) {
          this.birthday = "01.01.2000";
          return; // Возвращаем первое января 1000 года
        }

        // Проверка, чтобы дата не превышала текущую дату
        var currentDate = new Date();
        if (
          year > currentDate.getFullYear() ||
          (year === currentDate.getFullYear() &&
            month > currentDate.getMonth() + 1) ||
          (year === currentDate.getFullYear() &&
            month === currentDate.getMonth() + 1 &&
            day > currentDate.getDate())
        ) {
          this.birthday = currentDate.toLocaleDateString("ru-RU");
          return;
        }

        // Возвращаем введенную дату, если она прошла все проверки
        this.birthday = dateString;
        return;
      }
    },
    async sendData() {
      if (this.isExits) {
        this.v$.$validate();
        if (
          this.ch1 &&
          this.ch2 &&
          this.ch3 &&
          this.ch4 &&
          this.ch5 &&
          this.ch6
        ) {
          toaster.show(`Успешно отправлен!`, {
            type: "success",
          });
          this.v$.$reset();
          (this.surname = ""),
            (this.name = ""),
            (this.birthday = ""),
            (this.phone = ""),
            (this.ch1 = false),
            (this.ch2 = false),
            (this.ch3 = false),
            (this.ch4 = false),
            (this.ch5 = false),
            (this.ch6 = false);
          (this.isPhoneValue = false), (this.isExits = false);
        } else {

toaster.show(`Заполните все обязательные поля!`, {
      type: "error",
    });
}
      } else {
        this.v$.$validate();
        if (!this.v$.$invalid) {
          var formData = {};
          formData.name = this.name;
          formData.surname = this.surname;

          var dateComponents = this.birthday.split(".");
          var formattedDate =
            dateComponents[2] +
            "-" +
            dateComponents[1] +
            "-" +
            dateComponents[0];
          formData.birthday = formattedDate;

          var originalPhone = this.phone;
          var cleanedPhone = originalPhone.replace(/\D/g, "");
          formData.phone = cleanedPhone;
          await axios
            .post("https://admin.hawaii.kz/api/V1/save-client", formData)
            .then((response) => {
              toaster.show(`Успешно отправлен!`, {
                type: "success",
              });
              this.v$.$reset();
              (this.surname = ""),
                (this.name = ""),
                (this.birthday = ""),
                (this.phone = ""),
                (this.ch1 = false),
                (this.ch2 = false),
                (this.ch3 = false),
                (this.ch4 = false),
                (this.ch5 = false),
                (this.ch6 = false);
                (this.isPhoneValue = false), (this.isExits = false);
            })
            .catch((error) => {
              toaster.show(`Такой номер уже существует!`, {
                type: "error",
              });
              this.v$.$reset();
              (this.surname = ""),
                (this.name = ""),
                (this.birthday = ""),
                (this.phone = ""),
                (this.ch1 = false),
                (this.ch2 = false),
                (this.ch3 = false),
                (this.ch4 = false),
                (this.ch5 = false),
                (this.ch6 = false);
                (this.isPhoneValue = false), (this.isExits = false);
            });
        } else {

          toaster.show(`Заполните все обязательные поля!`, {
                type: "error",
              });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap");
/* Дефолтные стили для всех элементов (*) */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
  font-size: 16px; /* Размер шрифта по умолчанию для корневого элемента (html) */
}
body {
  overflow-x: hidden;
  font-family: Nunito, sans-serif; /* Шрифт по умолчанию для текста внутри body */
  line-height: 1.6; /* Межстрочный интервал (линейная высота) */
  background-color: #fff; /* Цвет фона по умолчанию */
  color: #000; /* Цвет текста по умолчанию */
  margin: 0; /* Убираем внешние отступы по умолчанию */
  padding: 0; /* Убираем внутренние отступы по умолчанию */
}
.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 25px 80px 90px 80px;
}
.main__top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main__top-center {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  color: rgba(37, 110, 216, 1);
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.72px;
  font-size: min(max(20px, calc(1.25rem + ((1vw - 3px) * 0.9877))), 36px);
}
.main__top p {
  background: var(
    --linear,
    linear-gradient(
      270deg,
      #ff865e 0%,
      rgba(255, 144, 81, 0.97) 41.32%,
      rgba(255, 211, 0, 0.8) 97.04%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: min(max(16px, calc(1rem + ((1vw - 3px) * 0.6173))), 26px);
  font-weight: 700;
  letter-spacing: 0.52px;
}
.main__top-logo {
  width: 12%;
  aspect-ratio: 2.15;
  object-fit: cover;
  @media(max-width:768px){
    width: 50%;
  }
}
.fields {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  row-gap: 20px;
}
.fields span {
  color: #256ed8;
  font-size: min(max(18px, calc(1.125rem + ((1vw - 3px) * 1.1111))), 36px);
  font-weight: 700;
}
.fields input {
  width: 100%;
  padding: 0 15px;
  height: 60px;
  font-size: 16px;
  outline: none;
  border-radius: 15px;
  border: 1px solid rgba(37, 110, 216, 0.6);
}
.inputs {
  flex-grow: 1;
  display: flex;
  gap: 20px;
  &-item {
    flex: 1;
    position: relative;
    p {
      position: absolute;
      top: calc(100%);
      left: 10px;
      font-size: 12px;
      color: rgb(165, 0, 0);
    }
    span {
      position: absolute;
      top: calc(100%);
      left: 10px;
      font-size: 14px;
      color: rgb(16, 213, 39);
    }
  }
}
input[type="text"]:focus {
  outline: 1px solid rgba(11, 78, 177, 0.6); /* Замените 'blue' на желаемый цвет обводки */
  /* Другие стили, которые вы хотите применить к активному текстовому полю */
}
.content {
  display: flex;
  align-items: center;
  gap: 60px;
}
.info {
  margin-top: 33px;
  width: 100%;
  max-width: 900px;
}
.info-title {
  color: #256ed8;
  font-size: min(max(16px, calc(1rem + ((1vw - 3px) * 0.3704))), 22px);
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.52px;
  margin-bottom: 15px;
}
.row {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  color: #2c3131;
  font-size: min(max(14px, calc(0.875rem + ((1vw - 3px) * 0.2469))), 18px);
  font-weight: 700;
  letter-spacing: 0.44px;
  margin-bottom: 25px;
}
input[type="checkbox"] {
  min-width: 21px;
  min-height: 21px;
  width: 21px;
  height: 21px;
  border-radius: 15px;
}
.content-img {
  width: 45%;
}
.content-img img {
  width: 100%;
  object-fit: cover;
}
article {
  margin-top: -10px;
  color: #256ed8;
  font-size: min(max(14px, calc(0.875rem + ((1vw - 3px) * 0.2469))), 18px);
  font-weight: 700;
  letter-spacing: 0.44px;
}
button {
  margin-top: 53px;
  width: 80%;
  border-radius: 15px;
  background: #256ed8;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.56px;
  max-width: 350px;
  border: none;
  outline: none;
}
button:hover {
  cursor: pointer;
  background: #0b5cd6;
}
@media screen and (max-width: 1200px) {
  .fields .inputs {
    flex-wrap: wrap;
    row-gap: 26px;
  }
  .fields .inputs-item {
    flex: none;
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 992px) {
  .main {
    padding: 10px 16px 80px 16px;
  }
  .main__top {
    flex-direction: column;
    gap: 30px;
  }
  .main__top-center {
    gap: 0px;
  }
  .fields .inputs-item {
    width: calc(calc(100% / 2) - 20px);
    padding: 0 12px;
    font-size: 15px;
  }
  input[type="text"]:focus {
    outline: 1px solid rgba(11, 78, 177, 0.6); /* Замените 'blue' на желаемый цвет обводки */
    /* Другие стили, которые вы хотите применить к активному текстовому полю */
  }
  .content {
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0px;
  }
  .info {
    margin-top: 33px;
    width: 100%;
  }
  .content-img {
    width: 100%;
  }
  button {
    width: 100%;
    margin-top: 30px;
    font-size: min(max(15px, calc(0.9375rem + ((1vw - 3px) * 0.8025))), 28px);
  }
  .row {
    gap: 10px;
  }
  input[type="checkbox"] {
    margin-top: 2px;
    min-width: 18px;
    min-height: 18px;
    width: 18px;
    height: 18px;
    border-radius: 15px;
  }
}
@media screen and (max-width: 600px) {
  .fields .inputs-item {
    width: 100%;
  }
}
.actborinp {
  border: 1px solid rgb(165, 0, 0) !important;
  &:focus {
    outline: none !important; /* Замените 'blue' на желаемый цвет обводки */
    /* Другие стили, которые вы хотите применить к активному текстовому полю */
  }
}
.actborcheck {
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
  box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
  appearance: none;
  border-radius: 3px !important;
}

.nodatas {
  width: 25%;
  flex: none;

  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}
.isExits {
  border: 1px solid rgb(57, 255, 57) !important;
}
</style>
